import { useMediaQuery, useTheme } from '@mui/material';

type Breakpoints = {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    xxl: boolean;
};

export default function useBreakpoints(): Breakpoints {
    const theme = useTheme();

    return {
        xs: useMediaQuery(theme.breakpoints.down('xs')),
        sm: useMediaQuery(theme.breakpoints.down('sm')),
        md: useMediaQuery(theme.breakpoints.down('md')),
        lg: useMediaQuery(theme.breakpoints.down('lg')),
        xl: useMediaQuery(theme.breakpoints.down('xl')),
        xxl: useMediaQuery(theme.breakpoints.up('xl')),
    };
}
