import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import setIcon from 'assets/images/icons/recovery-set.png';
import { constantColor } from 'constants/colors';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { constantURL } from 'constants/urls';
import { useChangePasswordMutation } from 'store/api/authentificationAPI';
import { APIError } from 'types/validations';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '..';
import ResetConfirmationPage from '../resetConfirmation';

type Props = {
    resetToken: string;
    login: string;
};

type HiddenFields = {
    password: boolean;
    confirm: boolean;
};

export default function ChangePasswordPage({
    login,
    resetToken,
}: Props): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [hidden, setHidden] = React.useState<HiddenFields>({
        password: false,
        confirm: false,
    });

    const [change, { isError, error, isLoading, isSuccess }] =
        useChangePasswordMutation();

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<{
        password: string;
        confirmPassword: string;
    }>({
        resolver: yupResolver<Yup.AnyObjectSchema>(
            Yup.object().shape({
                password: Yup.string()
                    .min(6, 'Too short!')
                    .required('Required'),
                confirmPassword: Yup.string()
                    .min(6, 'Too short!')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match!')
                    .required(),
            })
        ),
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    });

    const onSubmit = (): void => {
        change({
            newPassword: getValues('password'),
            resetToken,
        });
    };

    React.useEffect(() => {
        if (isError) ErrorAlert((error as APIError).data.message as string);
    }, [isError]);

    if (isLoading)
        return (
            <Box position="absolute" bottom="50%" left="50%">
                <CircularProgress />
            </Box>
        );

    return isSuccess ? (
        <ResetConfirmationPage login={login} password={getValues('password')} />
    ) : (
        <Container maxWidth="xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img src={setIcon} alt="set-password-icon" />
                    </Box>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={500}
                        fontSize={30}
                        textAlign="center"
                        sx={{
                            marginTop: 5,
                        }}
                    >
                        {t('Recovery.setnew')}
                    </Typography>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={400}
                        fontSize={16}
                        marginTop={2}
                        color={constantColor.GRAY_DARK}
                        textAlign="center"
                    >
                        {t('Recovery.newpass')}
                    </Typography>
                    <Container maxWidth="sm">
                        <Stack marginTop={5} spacing={2}>
                            <TextField
                                {...register('password')}
                                type={hidden.password ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => {
                                                setHidden((prev) => ({
                                                    ...prev,
                                                    password: !prev.password,
                                                }));
                                            }}
                                        >
                                            {hidden.password ? (
                                                <VisibilityOffIcon />
                                            ) : (
                                                <RemoveRedEyeIcon />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                                fullWidth
                                label="Password"
                            />
                            {errors.password && (
                                <Typography
                                    color="red"
                                    sx={{
                                        paddingY: 1,
                                    }}
                                >
                                    {errors.password.message}
                                </Typography>
                            )}
                            <TextField
                                {...register('confirmPassword')}
                                type={hidden.confirm ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            onClick={() => {
                                                setHidden((prev) => ({
                                                    ...prev,
                                                    confirm: !prev.confirm,
                                                }));
                                            }}
                                        >
                                            {hidden.confirm ? (
                                                <VisibilityOffIcon />
                                            ) : (
                                                <RemoveRedEyeIcon />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                                fullWidth
                                label="Confirm password"
                            />
                            {errors.confirmPassword && (
                                <Typography
                                    color="red"
                                    sx={{
                                        paddingY: 1,
                                    }}
                                >
                                    {errors.confirmPassword.message}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    borderRadius: 1,
                                    padding: 1,
                                    textTransform: 'none',
                                }}
                            >
                                <Typography
                                    fontFamily="Poppins"
                                    fontWeight={400}
                                    fontSize={16}
                                >
                                    {t('Recovery.resetPass')}
                                </Typography>
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate(constantURL.LOGIN);
                                }}
                                startIcon={<ArrowBackIcon />}
                                sx={{
                                    marginTop: 4,
                                    textTransform: 'none',
                                }}
                            >
                                <Typography
                                    fontWeight={500}
                                    fontSize={14}
                                    fontFamily="Poppins"
                                    color={constantColor.GRAY_DARK}
                                    textAlign="center"
                                >
                                    {t('Recovery.back')}
                                </Typography>
                            </Button>
                        </Stack>
                    </Container>
                </Stack>
            </form>
        </Container>
    );
}
