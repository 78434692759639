import React from 'react';
import { useAppSelector } from 'store';
import { Navigate } from 'react-router-dom';
import { constantURL } from 'constants/urls';

type Props = {
    component: React.ComponentType;
    restricted: boolean;
};

export default function PublicRoute({
    component: Component,
    restricted,
    ...props
}: Props): JSX.Element {
    const token = useAppSelector((state) => state.login.token);
    
    return token && restricted ? (
        <Navigate to={constantURL.HOME} />
    ) : (
        <Component {...props} />
    );
}
