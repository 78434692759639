export const font = {
    MAIN: 'Poppins',
    SECONDARY: 'Quattrocento Sans',

    WEIGHT_BASE: '400',
    WEIGHT_MEDIUM: '500',
    WEIGHT_BOLD: '700',

    SIZE_SMALL: '12px',
    SIZE_CHIP: '14px',
    SIZE_MEDIUM: '18px',
    SIZE_LINK: '22px',
    SIZE_DESC: '24px',
    SIZE_BUTTONS: '26px',
    SIZE_BUTTONS_SMALL: '16px',
    SIZE_HEADING: '30px',
    SIZE_LARGE: '50px',
};