import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { authentification } from './api/authentificationAPI';
import settingsReducer from './reducers/settings';
import loginReducer from './reducers/login';
import { codeSending } from './api/codeSendingAPI';
import { addCompanyInfo } from './api/departmentsAPI';
import { companyApi } from './api/companyAPI';
import { departmentApi } from './api/departmentAPI';

const reducers = combineReducers({
    [authentification.reducerPath]: authentification.reducer,
    [codeSending.reducerPath]: codeSending.reducer,
    [addCompanyInfo.reducerPath]: addCompanyInfo.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    settings: settingsReducer,
    login: loginReducer,
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(authentification.middleware)
            .concat(codeSending.middleware)
            .concat(addCompanyInfo.middleware)
            .concat(companyApi.middleware)
            .concat(departmentApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
