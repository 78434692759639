import { createTheme } from '@mui/material';
import { constantColor } from 'constants/colors';

export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: constantColor.MAIN,
            light: constantColor.MAIN_LIGHT,
            dark: constantColor.BLACK,
        },
        secondary: {
            main: constantColor.BLACK,
        },
    },
    shape: {
        borderRadius: 10,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderRadius: 5,
                    borderColor: constantColor.GRAY_TEXT,
                },
                contained: {
                    borderRadius: 15,
                },
            },
        },
    },
});
