import styled from 'styled-components';
import { constantColor } from 'constants/colors';
import { font } from 'constants/fonts';
import { Container } from 'components/components';
import { MuiChipsInput } from 'mui-chips-input';

export const CustomChipsInput = styled(MuiChipsInput)({
    '& label.Mui-focused': {
        color: `${constantColor.MAIN}`,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: `${constantColor.MAIN}`,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: `10px`,
        },
        '&.Mui-focused fieldset': {
            borderColor: `${constantColor.MAIN}`,
        },
    },
    '& .MuiChipsInput-Chip': {
        background: `${constantColor.CHIP_BACKGROUND}`,
        borderRadius: '10px',
        fontSize: `${font.SIZE_CHIP}`,
        color: `${constantColor.MAIN}`,
        fontFamily: `${font.MAIN}`,
        '& svg': {
            fill: `${constantColor.MAIN}`,
        },
    },
    '& .css-j4oyuz-MuiButtonBase-root-MuiChip-root.MuiChipsInput-Chip-Editing':
        {
            background: `${constantColor.MAIN}`,
        },
});

export const Wrapper = styled(Container)`
    * {
        scrollbar-width: thin;
        scrollbar-color: ${constantColor.SCROLL} ${constantColor.WHITE};
    }

    *::-webkit-scrollbar {
        width: 6px;
    }

    *::-webkit-scrollbar-track {
        background: ${constantColor.WHITE};
        border-radius: 4px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: ${constantColor.SCROLL};
        border-radius: 4px;
        height: 6px;
    }

    *::-webkit-scrollbar-thumb:horizontal {
        height: 6px;
    }

    button[disabled='disabled'],
    button:disabled {
        color: ${constantColor.WHITE};
        opacity: 0.6;
    }

    .header {
        padding: 36px 114px 0 114px;
        min-height: 127px;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .link {
            position: relative;
            font-family: ${font.MAIN};
            color: ${constantColor.MAIN};
            font-size: ${font.SIZE_MEDIUM};
            line-height: 27px;
            font-weight: ${font.WEIGHT_MEDIUM};
        }
        .menu-container {
            margin: 0 auto;
        }
        .menu-items {
            width: 703px;
            display: flex;
            justify-content: space-between;
        }
        .menu-link:hover {
            color: ${constantColor.MAIN};
            border-bottom: 1px solid ${constantColor.MAIN};
        }
        .brand {
            margin-top: 30px;
            position: absolute;
            bottom: 18px;
            margin-left: 4px;
            color: ${constantColor.MAIN};
            font-family: ${font.SECONDARY};
            font-weight: ${font.WEIGHT_BOLD};
            font-size: 25px;
        }
    }
    .heading {
        font-size: ${font.SIZE_HEADING};
        font-weight: ${font.WEIGHT_BASE};
        text-align: center;
        margin-top: 55px;
    }
    input[type='text']:disabled {
        cursor: not-allowed;
    }
`;
