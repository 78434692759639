export const constantColor = {
    MAIN: '#337E81',
    MAIN_LIGHT: '#337e81b0',
    WHITE: '#FFF',
    BLACK: '#000',
    GRAY: '#4B4B4B',
    GRAY_LIGHT: '#D4D4D4',
    GREY: '#F8F8F8',
    GRAY_DARK: '#898989',
    GRAY_TEXT: '#B3B3B3',
    SCROLL: '#C2C2C2',
    SCROLL_LIGHT: '#ECECEC',
    CHIP_BACKGROUND: '#E0EBEC',
};
