import styled from 'styled-components';
import { constantColor } from 'constants/colors';
import { font } from 'constants/fonts';

export const Palette = styled.div`
    border: 1.5px solid ${constantColor.GRAY_LIGHT};
    border-radius: 20px;
    padding: 23px;
    .react-colorful {
        height: 200px;
        width: 204px;
        margin: 0;
    }
`;

export const PaletteHeading = styled.h3`
    font-weight: ${font.WEIGHT_BASE};
    margin-bottom: 5px;
`;

export const ButtonContainer = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .save {
        padding: 15px 5px;
        width: 130px;
        height: 32px;
        font-size: ${font.SIZE_BUTTONS_SMALL};
        font-weight: ${font.WEIGHT_MEDIUM};
        border-radius: 4px;
    }
`;
