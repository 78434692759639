import { styled } from '@mui/material/styles';
import { constantColor } from 'constants/colors';
import Button, { ButtonProps } from '@mui/material/Button';
import { font } from 'constants/fonts';

export const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(constantColor.MAIN),
    backgroundColor: constantColor.MAIN,
    borderRadius: '15px',
    textTransform: 'none',
    fontFamily: font.MAIN,
    padding: '10px 50px',
    whiteSpace: 'nowrap',
    fontWeight: font.WEIGHT_MEDIUM,
    fontSize: '2.5vmin',
    '&:hover': {
        backgroundColor: constantColor.MAIN_LIGHT,
    },
}));
