import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import checkIcon from 'assets/images/icons/recovery-check.png';
import { useNavigate } from 'react-router-dom';
import { constantColor } from 'constants/colors';
import { constantURL } from 'constants/urls';
import {
    useGenerateVerificationCodeMutation,
    useSendTokenMutation,
    useValidateVerificationCodeMutation,
} from 'store/api/authentificationAPI';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { APIError } from 'types/validations';
import { useTranslation } from 'react-i18next';
import ChangePasswordPage from '../changePassword';
import { ErrorAlert } from '..';

type Props = {
    login: string;
};

export default function ValidatePasswordResetTokenPage({
    login,
}: Props): JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [resetToken, setResetToken] = React.useState<string>('');

    const [
        validate,
        {
            isError: isVerificationError,
            error: verificationError,
            isLoading: verificationLoading,
        },
    ] = useValidateVerificationCodeMutation();
    const [
        generate,
        {
            isError: isGenerateError,
            error: generateError,
            isLoading: generateLoading,
        },
    ] = useGenerateVerificationCodeMutation();
    const [
        send,
        { isError: isSendError, error: sendError, isLoading: sendLoading },
    ] = useSendTokenMutation();

    const {
        reset,
        register,
        getValues,
        formState: { errors },
    } = useForm<{
        code: string;
    }>({
        resolver: yupResolver<Yup.AnyObjectSchema>(
            Yup.object().shape({
                code: Yup.string().required('Required'),
            })
        ),
        defaultValues: {
            code: '',
        },
    });

    const generateCode = async (): Promise<void> => {
        const { code } = await generate({
            email: login,
        }).unwrap();

        await send({
            code,
            email: login,
        });
    };

    React.useEffect(() => {
        generateCode();
    }, []);

    React.useEffect(() => {
        if (isVerificationError)
            ErrorAlert((verificationError as APIError).data.message as string);
    }, [isVerificationError]);

    React.useEffect(() => {
        if (isGenerateError)
            ErrorAlert((generateError as APIError).data.message as string);
    }, [isGenerateError]);

    React.useEffect(() => {
        if (isSendError)
            ErrorAlert((sendError as APIError).data.message as string);
    }, [isSendError]);

    if (verificationLoading || generateLoading || sendLoading) {
        return (
            <Box position="absolute" bottom="50%" left="50%">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container>
            {resetToken ? (
                <ChangePasswordPage resetToken={resetToken} login={login} />
            ) : (
                <Stack>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img src={checkIcon} alt="check-email-icon" />
                    </Box>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={500}
                        fontSize={30}
                        marginTop={5}
                        textAlign="center"
                        sx={{
                            marginTop: 5,
                        }}
                    >
                        {t('Recovery.check')}
                    </Typography>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={400}
                        fontSize={16}
                        marginTop={2}
                        color={constantColor.GRAY_DARK}
                        textAlign="center"
                    >
                        {t('Recovery.sent')}
                    </Typography>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={500}
                        fontSize={16}
                        marginTop={1}
                        textAlign="center"
                    >
                        {login}
                    </Typography>
                    <Container maxWidth="xs">
                        <form>
                            <Stack>
                                <Box marginTop={5}>
                                    <TextField
                                        {...register('code')}
                                        label="Token"
                                        fullWidth
                                    />
                                    {errors.code && (
                                        <Typography
                                            color="red"
                                            sx={{
                                                paddingY: 1,
                                            }}
                                        >
                                            {errors.code.message}
                                        </Typography>
                                    )}
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    marginTop={2}
                                >
                                    <Button
                                        onClick={async () => {
                                            const { passwordResetToken } =
                                                await validate({
                                                    email: login,
                                                    code: getValues('code'),
                                                }).unwrap();

                                            setResetToken(passwordResetToken);
                                        }}
                                        sx={{
                                            width: '60%',
                                            borderRadius: 1,
                                            padding: 1,
                                            textTransform: 'none',
                                        }}
                                        fullWidth
                                        variant="contained"
                                    >
                                        <Typography
                                            fontFamily="Poppins"
                                            fontWeight={400}
                                            fontSize={16}
                                        >
                                            {t('Recovery.validate')}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Stack>
                        </form>
                    </Container>
                    <Box
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        flexWrap="wrap"
                        marginTop={5}
                    >
                        <Typography
                            fontFamily="Poppins"
                            fontSize={16}
                            textAlign="center"
                        >
                            {t('Recovery.didnt')}
                        </Typography>
                        <Button
                            onClick={() => {
                                generateCode();
                                reset();
                            }}
                            sx={{
                                textTransform: 'none',
                                paddingX: 1,
                                paddingY: 0,
                            }}
                        >
                            <Typography
                                fontFamily="Poppins"
                                fontWeight={500}
                                fontSize={16}
                            >
                                {t('Recovery.resend')}
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={() => {
                            navigate(constantURL.LOGIN);
                        }}
                        startIcon={<ArrowBackIcon />}
                        sx={{
                            marginTop: 4,
                            textTransform: 'none',
                        }}
                    >
                        <Typography
                            fontWeight={500}
                            fontSize={14}
                            fontFamily="Poppins"
                            color={constantColor.GRAY_DARK}
                            textAlign="center"
                        >
                            {t('Recovery.back')}
                        </Typography>
                    </Button>
                </Stack>
            )}
        </Container>
    );
}
