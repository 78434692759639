import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constantURL } from 'constants/urls';
import { signinForm, signupForm, UserResponse } from 'types/user';

export const authentification = createApi({
    reducerPath: 'authentification',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL,
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*');

            return headers;
        },
    }),

    endpoints: (build) => ({
        signIn: build.mutation<
            UserResponse,
            { login: string; password: string }
        >({
            query: (body: signinForm) => {
                return {
                    url: constantURL.OUT_SIGNIN,
                    method: 'post',
                    body,
                };
            },
        }),
        signUp: build.mutation({
            query: (body: signupForm) => {
                return {
                    url: constantURL.OUT_SIGNUP,
                    method: 'post',
                    body,
                };
            },
        }),
        generateVerificationCode: build.mutation<
            { code: string },
            { email: string }
        >({
            query: ({ email }) => {
                return {
                    url: `${constantURL.OUT_GENERATE_VERIFICATION_CODE}?login=${email}`,
                    method: 'post',
                };
            },
        }),
        validateVerificationCode: build.mutation<
            { passwordResetToken: string },
            { email: string; code: string }
        >({
            query: ({ code, email }) => {
                return {
                    url: constantURL.OUT_VALIDATE_VERIFICATION_CODE_PWD,
                    method: 'post',
                    body: { code, login: email },
                };
            },
        }),
        changePassword: build.mutation<
            undefined,
            { resetToken: string; newPassword: string }
        >({
            query: ({ resetToken, newPassword }) => {
                return {
                    url: constantURL.OUT_CHANGE_PWD,
                    method: 'put',
                    body: { resetToken, newPassword },
                };
            },
        }),
        sendToken: build.mutation<undefined, { email: string; code: string }>({
            query: ({ email, code }) => {
                return {
                    url: constantURL.OUT_SEND_CODE,
                    method: 'POST',
                    body: {
                        subject: 'Soundglide Password Reset Token',
                        body: code,
                        email,
                    },
                };
            },
        }),
    }),
});

export const {
    useSignInMutation,
    useSignUpMutation,
    useGenerateVerificationCodeMutation,
    useValidateVerificationCodeMutation,
    useChangePasswordMutation,
    useSendTokenMutation,
} = authentification;
