interface IReturnButtonTypes {
    SINGLE: string
    ARRAY: string
    SIMPLE: string
}
export const RETURN_BUTTON_TYPES: IReturnButtonTypes = {
    SINGLE: 'single',
    ARRAY: 'array',
    SIMPLE: 'simple'
}
export const HOME_PAGE = {
    HOME: 'Home',
    CALL_DATA: 'Call data',
    COMPANY: 'Company',
    EDIT_INFO:'Edit info',
    LOGOUT: 'Logout',
    DEFAULT_COLOR: `window.colors = {
            popupBackground: "rgba(255, 255, 255, 1)",
            itemBackground: "rgba(219, 244, 222, 1)",
            button: "rgba(33, 69, 37, 1)",
            text: "rgba(255, 255, 255, 1)",
            buttonText: 'white',
            initialButtonText: 'white',
            initialButtonBackground: '#214525',
        };`,
    BUTTON_TEXT: 'Department.buttonText',
    EMAIL_SENT: 'Home.emailSent',

}