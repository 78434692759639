import React, { useEffect } from 'react';
import logo from 'assets/images/logo.svg';
import google from 'assets/images/google.svg';
import {
    Box,
    Button,
    Checkbox,
    Container,
    DialogProps,
    Divider,
    Grid,
    Link,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { constantColor } from 'constants/colors';
import useBreakpoints from 'hooks/useBreakpoints';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
    useSignInMutation,
    useSignUpMutation,
} from 'store/api/authentificationAPI';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { signupForm } from 'types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { setToken, setUser } from 'store/reducers/login';
import { constantURL } from 'constants/urls';
import { APIError } from 'types/validations';
import { Alerts } from 'utils/alerts';
import WelcomeBack from '../welcomeBack';
import TermsConditions from './terms';

const CustomLink = styled(Link)`
    text-decoration: none;
    color: ${constantColor.MAIN};
    font-weight: 500;
`;

const SignUpValidationSchema = Yup.object().shape(
    {
        first_name: Yup.string().min(2, 'Too short!').required(),
        last_name: Yup.string().min(2, 'Too short!').required(),
        email: Yup.string()
            .email()
            .when('mobile_number', {
                is: (phone: string) => !phone || phone.length === 0,
                then: Yup.string()
                    .email('Invalid e-mail!')
                    .required('E-mail is required!'),
                otherwise: Yup.string(),
            }),
        mobile_number: Yup.string().when('email', {
            is: (email: string) => !email || email.length === 0,
            then: Yup.string().required('Phone number is required!'),
            otherwise: Yup.string(),
        }),
        password: Yup.string().min(6, 'Too short!').required(),
        confirmPassword: Yup.string()
            .min(6, 'Too short!')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required(),
    },
    [['email', 'mobile_number']]
);

export default function SignUpPage(): JSX.Element {
    const { sm, md } = useBreakpoints();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [agreed, setIsAgreed] = React.useState<boolean>(false);
    const [openTerms, setOpenTerms] = React.useState<boolean>(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

    const [signUp, { isLoading, isSuccess, isError, error }] =
        useSignUpMutation();
    const [signIn] = useSignInMutation();

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<signupForm>({
        resolver: yupResolver<Yup.AnyObjectSchema>(SignUpValidationSchema),
        defaultValues: {
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            mobile_number: undefined,
            password: undefined,
            confirmPassword: undefined,
        },
    });

    const onSubmit: SubmitHandler<signupForm> = (values) => {
        delete values.confirmPassword;
        const valuesMap = Object.fromEntries(
            Object.entries(values).filter(([_, v]) => !!v)
        );

        signUp(valuesMap as unknown as signupForm);
    };

    useEffect(() => {
        if (isSuccess) {
            Alerts.fire({
                title: t('SignUp.successfullyCreated'),
                showConfirmButton: true,
                icon: 'success',
                confirmButtonColor: constantColor.MAIN,
            }).then(() => {
                signIn({
                    login: getValues('email') || getValues('mobile_number'),
                    password: getValues('password'),
                })
                    .unwrap()
                    .then((res) => {
                        dispatch(
                            setToken({
                                token: res.token,
                                remember: true,
                            })
                        );
                        navigate(constantURL.HOME);
                    });
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        const typedError = error as APIError;

        if (isError) {
            if (typedError.data) {
                if (typedError.data.statusCode === 409) {
                    Alerts.fire({
                        title: t('SignUp.alreadyExists'),
                        showConfirmButton: true,
                        icon: 'error',
                        confirmButtonColor: constantColor.MAIN,
                    });
                }
            } else {
                Alerts.fire({
                    title: t('Errors.server'),
                    showConfirmButton: true,
                    icon: 'error',
                    confirmButtonColor: constantColor.MAIN,
                });
            }
        }
    }, [isError]);

    const handleClickOpen = (scrollType: DialogProps['scroll']): void => {
        setOpenTerms(true);
        setScroll(scrollType);
    };

    function checkBoxLabel(): JSX.Element {
        return (
            <span className="agree-link">
                {t('SignUp.agree')}
                <Link
                    onClick={(event) => {
                        handleClickOpen('paper');
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                    className="link"
                    underline="none"
                >
                    {t('SignUp.terms')}
                </Link>
            </span>
        );
    }

    return (
        <Container maxWidth="xl">
            <Box
                display={!sm && !md ? 'grid' : undefined}
                gridTemplateColumns={!sm && !md ? '1fr 1fr' : undefined}
                minWidth={!sm && !md ? 1280 : undefined}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box padding={sm ? 0 : 5}>
                        <Box
                            display="flex"
                            justifyContent={sm ? 'center' : 'flex-start'}
                            marginTop={2}
                            marginBottom={8}
                        >
                            <CustomLink href="/">
                                <img src={logo} alt="logo" />
                            </CustomLink>
                            <Typography
                                fontFamily="Quattrocento Sans"
                                fontWeight="bold"
                                fontSize={20}
                                color={constantColor.MAIN}
                                alignSelf="center"
                                marginLeft={1}
                            >
                                Soundglide.com
                            </Typography>
                        </Box>
                        <Box
                            textAlign={sm ? 'center' : 'start'}
                            marginBottom={5}
                        >
                            <Typography
                                marginBottom={1}
                                variant="h4"
                                fontFamily="Poppins"
                                fontWeight={500}
                            >
                                {t('SignUp.signUp')}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontFamily="Poppins"
                                color={constantColor.GRAY_DARK}
                            >
                                {t('SignUp.details')}
                            </Typography>
                        </Box>
                        <Grid container spacing={2} marginBottom={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...register('first_name')}
                                    fullWidth
                                    label={t('SignUp.firstName')}
                                    error={Boolean(errors.first_name?.message)}
                                    helperText={errors.first_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...register('last_name')}
                                    fullWidth
                                    label={t('SignUp.lastName')}
                                    error={Boolean(errors.last_name?.message)}
                                    helperText={errors.last_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...register('email')}
                                    fullWidth
                                    label={t('SignUp.email')}
                                    error={Boolean(errors.email?.message)}
                                    helperText={errors.email?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...register('mobile_number')}
                                    fullWidth
                                    label={t('SignUp.phone')}
                                    error={Boolean(
                                        errors.mobile_number?.message
                                    )}
                                    helperText={errors.mobile_number?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...register('password')}
                                    fullWidth
                                    type="password"
                                    label={t('SignUp.password')}
                                    error={Boolean(errors.password?.message)}
                                    helperText={errors.password?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    {...register('confirmPassword')}
                                    fullWidth
                                    type="password"
                                    label={t('SignUp.confirm')}
                                    error={Boolean(
                                        errors.confirmPassword?.message
                                    )}
                                    helperText={errors.confirmPassword?.message}
                                />
                            </Grid>
                        </Grid>
                        <Box
                            display="flex"
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={5}
                        >
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    sx={{
                                        paddingLeft: 0,
                                    }}
                                    value={agreed}
                                    defaultChecked
                                    onChange={() => {
                                        setIsAgreed(!agreed);
                                    }}
                                />
                                <Typography fontFamily="Poppins">
                                    {checkBoxLabel()}
                                </Typography>
                            </Box>
                        </Box>
                        <Stack>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{
                                    padding: 2,
                                    fontFamily: 'Poppins',
                                    fontSize: 18,
                                    textTransform: 'none',
                                    maxWidth: 500,
                                    alignSelf: 'center',
                                }}
                                title="Login"
                                disabled={isLoading || agreed}
                                type="submit"
                            >
                                {t('SignUp.signUp')}
                            </Button>
                            <Divider
                                sx={{
                                    paddingY: 2,
                                    margin: 'auto',
                                    alignItems: 'center',
                                    width: '100%',
                                    maxWidth: 500,
                                }}
                            >
                                <Typography
                                    fontFamily="Poppins"
                                    color={constantColor.GRAY_TEXT}
                                >
                                    {t('SignUp.or')}
                                </Typography>
                            </Divider>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    padding: 2,
                                    maxWidth: 500,
                                    alignSelf: 'center',
                                }}
                            >
                                <img src={google} alt="google" />
                                <Typography
                                    textTransform="none"
                                    fontFamily="Poppins"
                                    marginLeft={2}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >
                                    {t('SignIn.continue')} Google
                                </Typography>
                            </Button>
                        </Stack>
                        <Box marginY={3}>
                            <Typography
                                color={constantColor.GRAY_TEXT}
                                fontFamily="Poppins"
                                textAlign="center"
                            >
                                {t('SignUp.already')}{' '}
                                <CustomLink
                                    underline="none"
                                    href={constantURL.SIGNIN}
                                >
                                    {t('SignIn.login')}
                                </CustomLink>
                            </Typography>
                        </Box>
                        <TermsConditions
                            open={openTerms}
                            setOpen={setOpenTerms}
                            scroll={scroll}
                        />
                    </Box>
                </form>
                {!sm && !md && <WelcomeBack />}
                {isError && error && (
                    <Snackbar
                        open={isError}
                        autoHideDuration={5000}
                        message={
                            (error as APIError).data
                                ? (error as APIError).data.message
                                      .toString()
                                      .replace(/,/g, ' | ')
                                : t('Errors.server')
                        }
                    />
                )}
            </Box>
        </Container>
    );
}
