import React from 'react';
import okIcon from 'assets/images/icons/recovery-ok.png';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Stack,
    Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { constantURL } from 'constants/urls';
import { constantColor } from 'constants/colors';
import { useSignInMutation } from 'store/api/authentificationAPI';
import { APIError } from 'types/validations';
import { useDispatch } from 'react-redux';
import { setToken } from 'store/reducers/login';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '..';

type Props = {
    login: string;
    password: string;
};

export default function ResetConfirmationPage({
    login,
    password,
}: Props): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [signIn, { isError, error, isLoading }] = useSignInMutation();

    React.useEffect(() => {
        if (isError) ErrorAlert((error as APIError).data.message as string);
    }, [isError]);

    if (isLoading)
        return (
            <Box position="absolute" bottom="50%" left="50%">
                <CircularProgress />
            </Box>
        );

    return (
        <Container maxWidth="xl">
            <Stack>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={okIcon} alt="ok-icon" />
                </Box>
                <Typography
                    fontFamily="Poppins"
                    fontWeight={500}
                    fontSize={30}
                    textAlign="center"
                    sx={{
                        marginTop: 5,
                    }}
                >
                    {t('Recovery.resetOk')}
                </Typography>
                <Typography
                    fontFamily="Poppins"
                    fontWeight={400}
                    fontSize={16}
                    marginTop={2}
                    color={constantColor.GRAY_DARK}
                    textAlign="center"
                >
                    {t('Recovery.success')}
                </Typography>
                <Container maxWidth="xs">
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            marginTop: 5,
                        }}
                        onClick={() => {
                            signIn({
                                login,
                                password,
                            })
                                .unwrap()
                                .then((data) => {
                                    dispatch(
                                        setToken({
                                            token: data.token,
                                            remember: true,
                                        })
                                    );
                                    navigate(constantURL.HOME);
                                });
                        }}
                    >
                        <Typography>{t('Recovery.continue')}</Typography>
                    </Button>
                </Container>
                <Button
                    onClick={() => {
                        navigate(constantURL.LOGIN);
                    }}
                    startIcon={<ArrowBackIcon />}
                    sx={{
                        marginTop: 4,
                        textTransform: 'none',
                    }}
                >
                    <Typography
                        fontWeight={500}
                        fontSize={14}
                        fontFamily="Poppins"
                        color={constantColor.GRAY_DARK}
                        textAlign="center"
                    >
                        {t('Recovery.back')}
                    </Typography>
                </Button>
            </Stack>
        </Container>
    );
}
