import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { SearchOutlined, Clear } from '@mui/icons-material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompanyMutation } from 'store/api/companyAPI';
import { useGetDepartmentsMutation } from 'store/api/departmentAPI';
import logo from 'assets/images/logo.svg';
import noImage from 'assets/images/no-image.svg';
import { constantColor } from 'constants/colors';
import useBreakpoints from 'hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';
import getFontSize from 'utils/getFontSize';
import { APIError } from 'types/validations';
import { Alerts } from 'utils/alerts';

type Params = {
    urlId: string;
};

type CenterComponentProps = {
    component: React.ReactNode;
};

function CenterComponent({ component }: CenterComponentProps): JSX.Element {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="10%"
        >
            {component}
        </Box>
    );
}

export default function CompanyPage(): JSX.Element {
    const { urlId } = useParams<Params>();
    const { xs, sm } = useBreakpoints();
    const { t } = useTranslation();
    const companyId = urlId?.split('-').pop();
    const [tab, setTab] = React.useState<number>(0);
    const [query, setQuery] = React.useState<string>('');    

    const [
        getCompany,
        {
            isLoading: isLoadingCompany,
            isError: isErrorCompany,
            data: dataCompany,
            error: errorCompany,
        },
    ] = useGetCompanyMutation();
    const [
        getDepartments,
        {
            isLoading: isLoadingDepartments,
            isError: isErrorDeparments,
            data: dataDepartments,
            error: errorDepartments,
        },
    ] = useGetDepartmentsMutation();

    React.useEffect(() => {
        const fetchCompany = async (): Promise<void> => {
            if (companyId) {
                await getCompany(companyId);
                await getDepartments(companyId);
            }
        };

        fetchCompany();
    }, []);
    
    React.useEffect(() => {
        const typedError = errorCompany as APIError;

        if (isErrorCompany) {
            if (typedError.data) {
                Alerts.fire({
                    title: typedError.data.message,
                    showConfirmButton: true,
                    icon: 'error',
                    confirmButtonColor: constantColor.MAIN,
                });
            } else {
                Alerts.fire({
                    title: t('Errors.server'),
                    showConfirmButton: true,
                    icon: 'error',
                    confirmButtonColor: constantColor.MAIN,
                });
            }
        }
    }, [isErrorCompany]);

    React.useEffect(() => {
        const typedError = errorDepartments as APIError;

        if (isErrorDeparments) {
            if (typedError.data) {
                Alerts.fire({
                    title: typedError.data.message,
                    showConfirmButton: true,
                    icon: 'error',
                    confirmButtonColor: constantColor.MAIN,
                });
            } else {
                Alerts.fire({
                    title: t('Errors.server'),
                    showConfirmButton: true,
                    icon: 'error',
                    confirmButtonColor: constantColor.MAIN,
                });
            }
        }
    }, [isErrorDeparments]);

    if (isLoadingCompany) {
        return <CenterComponent component={<CircularProgress />} />;
    }

    if (!dataCompany || !dataDepartments)
        return <CenterComponent component={<CircularProgress />} />;

    return (
        <Box>
            <Box
                padding={3}
                margin={0}
                boxShadow={`0px 5px 20px -1px ${constantColor.GRAY_LIGHT}`}
            >
                <Container maxWidth="xl">
                    <Box
                        flexDirection={sm ? 'column' : 'row'}
                        display="flex"
                        alignItems="center"
                    >
                        <img src={logo} alt="logo" />
                        <Typography
                            fontFamily="Quattrocento Sans"
                            fontWeight="bold"
                            fontSize={getFontSize(xs, sm)}
                            color={constantColor.MAIN}
                            alignSelf="center"
                            marginLeft={1}
                        >
                            soundglide.com/
                            {dataCompany.name.toLowerCase().replace(' ', '-')}
                        </Typography>
                    </Box>
                </Container>
            </Box>
            {/* eslint-disable-next-line */}
            {isLoadingDepartments || !dataDepartments ? (
                <CenterComponent component={<CircularProgress />} />
            ) : !dataDepartments.length ? (
                <CenterComponent
                    component={
                        <Typography fontFamily="Poppins" fontSize={24}>
                            No departments
                        </Typography>
                    }
                />
            ) : (
                <Box>
                    <Container maxWidth="xl">
                        <Box marginTop={5}>
                            <Grid
                                marginBottom={xs || sm ? 3 : 0}
                                container
                                spacing={2}
                                display="flex"
                            >
                                <Grid alignSelf="flex-end" item xs={12} sm={8}>
                                    <Stack
                                        overflow="auto"
                                        direction="row"
                                        spacing={1}
                                    >
                                        {dataDepartments.map(
                                            (department, i) => {
                                                const active = tab === i;

                                                return (
                                                    <Button
                                                        key={i}
                                                        sx={{
                                                            borderRadius:
                                                                '5px 5px 0 0 !important',
                                                        }}
                                                        size="small"
                                                        disableElevation={
                                                            !active
                                                        }
                                                        variant={
                                                            active
                                                                ? 'contained'
                                                                : 'outlined'
                                                        }
                                                        onClick={() => {
                                                            setTab(i);
                                                        }}
                                                    >
                                                        <Typography
                                                            fontSize={12}
                                                            fontFamily="Poppins"
                                                            overflow="hidden"
                                                            textOverflow="ellipsis"
                                                            textTransform="capitalize"
                                                            noWrap
                                                        >
                                                            {department.title || department.name}
                                                        </Typography>
                                                    </Button>
                                                );
                                            }
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        value={query}
                                        onChange={({ target: { value } }) => {
                                            setQuery(value);
                                        }}
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            endAdornment: (
                                                <Stack
                                                    spacing={1}
                                                    direction="row"
                                                >
                                                    {query && (
                                                        <IconButton
                                                            onClick={() => {
                                                                setQuery('');
                                                            }}
                                                            size="small"
                                                        >
                                                            <Clear
                                                                fontSize="small"
                                                                color="disabled"
                                                            />
                                                        </IconButton>
                                                    )}
                                                    <IconButton
                                                        disabled
                                                        size="small"
                                                    >
                                                        <SearchOutlined
                                                            fontSize="small"
                                                            color="primary"
                                                        />
                                                    </IconButton>
                                                </Stack>
                                            ),
                                            style: {
                                                height: 25,
                                                fontSize: 14,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                rowGap={5}
                                sx={{
                                    backgroundColor: constantColor.GREY,
                                    padding: 2,
                                }}
                            >
                                {dataDepartments.map((department, i) => (
                                    <Grid
                                        key={i}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        item
                                        xs={12}
                                        sm={4}
                                        md={3}
                                    >
                                        <img
                                            src={
                                                department.image_url || noImage
                                            }
                                            style={{
                                                borderRadius: '100%',
                                                width: 80,
                                                height: 80,
                                                marginBottom: 2,
                                            }}
                                            alt={`department_${department.id}_picture`}
                                        />
                                        <Typography
                                            fontFamily="Poppins"
                                            fontWeight={600}
                                            color={constantColor.GRAY}
                                            fontSize={14}
                                            marginBottom={2}
                                        >
                                            {department.title || department.name}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                borderRadius: '8px !important',
                                                width: '80%',
                                            }}
                                        >
                                            <Typography
                                                fontSize={14}
                                                fontFamily="Poppins"
                                                textTransform="capitalize"
                                            >
                                                {t('Company.call')}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            )}
        </Box>
    );
}
