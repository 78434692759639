import React from 'react';
import { constantURL } from 'constants/urls';
import { Route, Routes } from 'react-router-dom';
import Home from 'pages/home';
import SecuredRoute from 'components/securedRoute';
import PublicRoute from 'components/publicRoute';
import CompanyPage from 'pages/company';
import ForgotPasswordPage from 'pages/forgotPassword';
import LoginPage from './pages/authentification';

function App(): JSX.Element {
    return (
        <Routes>
            <Route
                path="*"
                element={<PublicRoute restricted component={LoginPage} />}
            />
            <Route
                path={constantURL.FORGOT_PASSWORD}
                element={
                    <PublicRoute restricted component={ForgotPasswordPage} />
                }
            />
            <Route
                path={constantURL.HOME}
                element={<SecuredRoute component={<Home />} />}
            />
            <Route path={constantURL.COMPANY} element={<CompanyPage />} />
        </Routes>
    );
}

export default App;
