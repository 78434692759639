import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    Grid,
    IconButton,
    Modal,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from 'assets/images/logo.svg';
import forgotIcon from 'assets/images/icons/recovery-forgot.png';
import useBreakpoints from 'hooks/useBreakpoints';
import { CustomLink } from 'pages/authentification/singIn';
import React from 'react';
import { constantColor } from 'constants/colors';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useGenerateVerificationCodeMutation } from 'store/api/authentificationAPI';
import { useNavigate } from 'react-router-dom';
import { constantURL } from 'constants/urls';
import { Alerts } from 'utils/alerts';
import { APIError } from 'types/validations';
import ValidatePasswordResetTokenPage from './validateToken';

export const ErrorAlert = (text: string): void => {
    Alerts.fire({
        title: 'Error!',
        icon: 'error',
        text,
        confirmButtonColor: constantColor.MAIN,
    });
};

export default function ForgotPasswordPage(): JSX.Element {
    const { sm } = useBreakpoints();
    const [code, setCode] = React.useState<string>('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [
        generateCode,
        {
            isError: isGenerateCodeError,
            error: generateCodeError,
            isLoading: generateCodeLoading,
        },
    ] = useGenerateVerificationCodeMutation();

    const {
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm<{
        email: string;
    }>({
        resolver: yupResolver<Yup.AnyObjectSchema>(
            Yup.object().shape({
                email: Yup.string()
                    .email('Invalid email!')
                    .required('Required'),
            })
        ),
        defaultValues: {
            email: '',
        },
    });

    const generateCodeHelper = (email: string): Promise<{ code: string }> => {
        return generateCode({
            email,
        }).unwrap();
    };

    const onSubmit: SubmitHandler<{ email: string }> = async ({ email }) => {
        const { code: token } = await generateCodeHelper(email);

        setCode(token);
    };

    React.useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    React.useEffect(() => {
        if (isGenerateCodeError)
            ErrorAlert((generateCodeError as APIError).data.message as string);
    }, [isGenerateCodeError]);

    if (generateCodeLoading)
        return (
            <Box position="absolute" bottom="50%" left="50%">
                <CircularProgress />
            </Box>
        );

    return (
        <Container maxWidth="xl">
            <Box
                display="flex"
                justifyContent={sm ? 'center' : 'flex-start'}
                marginTop={2}
                marginBottom={8}
            >
                <CustomLink href="/">
                    <img src={logo} alt="logo" />
                </CustomLink>
                <Typography
                    fontFamily="Quattrocento Sans"
                    fontWeight="bold"
                    fontSize={20}
                    color={constantColor.MAIN}
                    alignSelf="center"
                    marginLeft={1}
                >
                    Soundglide.com
                </Typography>
            </Box>
            {code ? (
                <ValidatePasswordResetTokenPage login={getValues('email')} />
            ) : (
                <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box display="flex" justifyContent="center">
                        <img src={forgotIcon} alt="forgot-password-icon" />
                    </Box>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={500}
                        fontSize={30}
                        marginTop={5}
                        textAlign="center"
                    >
                        {t('Recovery.forgot')}
                    </Typography>
                    <Typography
                        fontFamily="Poppins"
                        fontWeight={400}
                        fontSize={16}
                        marginTop={2}
                        color={constantColor.GRAY_DARK}
                        textAlign="center"
                    >
                        {t('Recovery.noworries')}
                    </Typography>
                    <Container
                        maxWidth="sm"
                        sx={{
                            marginTop: 10,
                        }}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box>
                                <TextField
                                    {...register('email')}
                                    fullWidth
                                    label={t('Fields.email')}
                                />
                                {errors.email && (
                                    <Typography
                                        color="red"
                                        sx={{
                                            paddingY: 1,
                                        }}
                                    >
                                        {errors.email.message}
                                    </Typography>
                                )}
                            </Box>
                            <Stack>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        borderRadius: 1,
                                        padding: 2,
                                        textTransform: 'none',
                                        marginTop: 5,
                                    }}
                                >
                                    <Typography
                                        fontWeight={500}
                                        fontSize={16}
                                        fontFamily="Poppins"
                                    >
                                        {t('Recovery.sendVerificationCode')}
                                    </Typography>
                                </Button>
                                <Button
                                    onClick={() => {
                                        navigate(constantURL.LOGIN);
                                    }}
                                    startIcon={<ArrowBackIcon />}
                                    sx={{
                                        marginTop: 4,
                                        textTransform: 'none',
                                    }}
                                >
                                    <Typography
                                        fontWeight={500}
                                        fontSize={14}
                                        fontFamily="Poppins"
                                        color={constantColor.GRAY_DARK}
                                    >
                                        {t('Recovery.back')}
                                    </Typography>
                                </Button>
                            </Stack>
                        </form>
                    </Container>
                </Stack>
            )}
        </Container>
    );
}
