export const constantURL = {
    HOME: '/home',
    LOGIN: '/login',
    SIGNIN: '/signin',
    SIGNUP: '/signup',
    RECOVERY: '/password-recovery',
    FORGOT_PASSWORD: '/forgot-password',
    RESET: '/password-reset',
    COMPANY: 'company/:urlId',

    OUT_SIGNIN: 'auth/login',
    OUT_SIGNUP: 'users/create/user',
    OUT_RESET_PASSWORD: '/',
    OUT_SEND_VERIFICATION_CODE: '',
    OUT_GENERATE_VERIFICATION_CODE: 'users/generate-verification-code',
    OUT_VALIDATE_VERIFICATION_CODE_PWD:
        'users/validate-verification-code-password-reset',
    OUT_CHANGE_PWD: 'users/change-password',
    OUT_SEND_CODE: 'messages/send/email',
    OUT_SEND_DEPARTMENTS: 'departments/create',
    OUT_SEND_COMPANY: 'companies/create',
    OUT_GET_COMPANY: 'companies/get/id',
    OUT_GET_DEPARTMENTS: 'companies/departments',
};
