import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginState, userState } from 'types/user';

const initialState: loginState = {
    user: null,
    token: localStorage.getItem('token') || '',
};

export const login = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setToken: (
            state,
            action: PayloadAction<{ token: string; remember: boolean }>
        ) => {
            state.token = action.payload.token;
            if (action.payload.remember)
                localStorage.setItem('token', action.payload.token);
        },
        setUser: (state, action: PayloadAction<userState>) => {
            state.user = action.payload;
        },
    },
});

export const { setToken, setUser } = login.actions;
export default login.reducer;
