import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { constantURL } from 'constants/urls';
import SignIn from './singIn';
import SignUp from './signUp';

function LoginPage(): JSX.Element {
    return (
        <Routes>
            <Route path="*" element={<Navigate to={constantURL.SIGNIN} />} />
            <Route path={constantURL.SIGNIN} element={<SignIn />} />
            <Route path={constantURL.SIGNUP} element={<SignUp />} />
        </Routes>
    );
}

export default LoginPage;
