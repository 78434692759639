import React, { useEffect } from 'react';
import logo from 'assets/images/logo.svg';
import google from 'assets/images/google.svg';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Link,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { constantColor } from 'constants/colors';
import useBreakpoints from 'hooks/useBreakpoints';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSignInMutation } from 'store/api/authentificationAPI';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'constants/validationsHook';
import { SubmitHandler, useForm } from 'react-hook-form';
import { signinForm } from 'types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { setToken } from 'store/reducers/login';
import { constantURL } from 'constants/urls';
import { APIError } from 'types/validations';
import WelcomeBack from '../welcomeBack';

export const CustomLink = styled(Link)`
    text-decoration: none;
    color: ${constantColor.MAIN};
    font-weight: 500;
`;

export default function LoginPage(): JSX.Element {
    const { sm, md } = useBreakpoints();
    const [signIn, { data, isLoading, isSuccess, isError, error }] =
        useSignInMutation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { signInSchema } = useValidation();
    const navigate = useNavigate();
    const [remember, setRemember] = React.useState<boolean>(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<signinForm>({
        resolver: yupResolver<Yup.AnyObjectSchema>(signInSchema),
        defaultValues: {
            login: '',
            password: '',
        },
    });

    const onSubmit: SubmitHandler<signinForm> = (values) => {
        signIn(values);
    };

    useEffect(() => {
        if (isSuccess && data) {
            dispatch(setToken({ token: data.token, remember }));
            navigate(constantURL.HOME);
        }
    }, [isSuccess]);

    return (
        <Container maxWidth="xl">
            <Box
                display={!sm && !md ? 'grid' : undefined}
                gridTemplateColumns={!sm && !md ? '1fr 1fr' : undefined}
                minWidth={!sm && !md ? 1080 : undefined}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box padding={sm ? 0 : 5}>
                        <Box
                            display="flex"
                            justifyContent={sm ? 'center' : 'flex-start'}
                            marginTop={2}
                            marginBottom={8}
                        >
                            <CustomLink href="/">
                                <img src={logo} alt="logo" />
                            </CustomLink>
                            <Typography
                                fontFamily="Quattrocento Sans"
                                fontWeight="bold"
                                fontSize={20}
                                color={constantColor.MAIN}
                                alignSelf="center"
                                marginLeft={1}
                            >
                                Soundglide.com
                            </Typography>
                        </Box>
                        <Box
                            textAlign={sm ? 'center' : 'start'}
                            marginBottom={5}
                        >
                            <Typography
                                marginBottom={1}
                                variant="h4"
                                fontFamily="Poppins"
                                fontWeight={500}
                            >
                                {t('SignIn.login')}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontFamily="Poppins"
                                color={constantColor.GRAY_DARK}
                            >
                                {t('SignIn.details')}
                            </Typography>
                        </Box>
                        <Stack spacing={2} marginBottom={2}>
                            <TextField
                                {...register('login')}
                                label={t('SignIn.emailPhone')}
                                error={Boolean(errors.login?.message)}
                                helperText={errors.login?.message}
                                FormHelperTextProps={{
                                    sx: {
                                        marginLeft: 1,
                                        fontFamily: 'Poppins',
                                    },
                                }}
                            />
                            <TextField
                                {...register('password')}
                                type="password"
                                label={t('SignIn.password')}
                                error={Boolean(errors.password?.message)}
                                helperText={errors.password?.message}
                                FormHelperTextProps={{
                                    sx: {
                                        marginLeft: 1,
                                        fontFamily: 'Poppins',
                                    },
                                }}
                            />
                        </Stack>
                        <Box
                            display="flex"
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="space-between"
                            marginBottom={5}
                        >
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    sx={{
                                        paddingLeft: 0,
                                    }}
                                    value={remember}
                                    defaultChecked
                                    onChange={() => {
                                        setRemember(!remember);
                                    }}
                                />
                                <Typography fontFamily="Poppins">
                                    {t('SignIn.remember')}
                                </Typography>
                            </Box>
                            <CustomLink
                                underline="none"
                                href={constantURL.FORGOT_PASSWORD}
                            >
                                <Typography fontFamily="Poppins">
                                    {t('SignIn.forgot')}
                                </Typography>
                            </CustomLink>
                        </Box>
                        <Stack>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{
                                    padding: 2,
                                    fontFamily: 'Poppins',
                                    fontSize: 18,
                                    textTransform: 'none',
                                    maxWidth: 500,
                                    alignSelf: 'center',
                                }}
                                title="Login"
                                disabled={isLoading}
                                type="submit"
                            >
                                {t('SignIn.login')}
                            </Button>
                            <Divider
                                sx={{
                                    paddingY: 2,
                                    margin: 'auto',
                                    alignItems: 'center',
                                    width: '100%',
                                    maxWidth: 500,
                                }}
                            >
                                <Typography
                                    fontFamily="Poppins"
                                    color={constantColor.GRAY_TEXT}
                                >
                                    {t('SignIn.or')}
                                </Typography>
                            </Divider>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    padding: 2,
                                    maxWidth: 500,
                                    alignSelf: 'center',
                                }}
                            >
                                <img src={google} alt="google" />
                                <Typography
                                    textTransform="none"
                                    fontFamily="Poppins"
                                    marginLeft={2}
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >
                                    {t('SignIn.continue')} Google
                                </Typography>
                            </Button>
                        </Stack>
                        <Box marginY={3}>
                            <Typography
                                color={constantColor.GRAY_TEXT}
                                fontFamily="Poppins"
                                textAlign="center"
                            >
                                {t('SignIn.dontHave')}{' '}
                                <CustomLink
                                    underline="none"
                                    href={constantURL.SIGNUP}
                                >
                                    {t('SignUp.signUp')}
                                </CustomLink>
                            </Typography>
                        </Box>
                    </Box>
                </form>
                {!sm && !md && <WelcomeBack />}
                {isError && error && (
                    <Snackbar
                        open={isError}
                        autoHideDuration={5000}
                        message={(error as APIError).data.message
                            .toString()
                            .replace(/,/g, ' | ')}
                    />
                )}
            </Box>
        </Container>
    );
}
