import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useValidation } from 'constants/validationsHook';
import React, { SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddDepartmentMutation } from 'store/api/departmentsAPI';
import { Department } from 'types/department';
import * as Yup from 'yup';

export type DepartmentProps = {
    title?: string;
    companyId?: number;
    simple?: boolean;
    index?: number;
    setDepartmentData?: React.Dispatch<SetStateAction<Department[]>>;
};

export default function DepartmentPage({
    title = '',
    companyId,
    simple,
    setDepartmentData,
}: DepartmentProps): React.ReactElement {
    const { t } = useTranslation();
    const [sendDepartmentInfo, { isSuccess, isLoading }] =
        useAddDepartmentMutation();
    const { departmentSchema, departmentSchemaSimple } = useValidation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Department>({
        resolver: yupResolver<Yup.AnyObjectSchema>(
            simple ? departmentSchemaSimple : departmentSchema
        ),
        defaultValues: {
            department: '',
            title: '',
            name: '',
            phone_number: '',
            email: '',
            image_url: '',
        },
    });

    const onSubmit: SubmitHandler<Department> = (values) => {
        if (companyId) {
            const valuesMap = Object.fromEntries(
                Object.entries(values).filter(([_, v]) => !!v)
            );

            sendDepartmentInfo({
                ...(valuesMap as unknown as Department),
                companyId,
            })
                .unwrap()
                .then((data: Department) => {
                    if (setDepartmentData)
                        setDepartmentData((prev) => [...prev, data]);
                });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h5" marginBottom={2} fontFamily="Poppins">
                    {title}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...register('phone_number')}
                        disabled={isLoading || isSuccess}
                        label={t('Department.phone')}
                        error={Boolean(errors.phone_number?.message)}
                        helperText={errors.phone_number?.message || ' '}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...register('department')}
                        disabled={isLoading || isSuccess}
                        label={t('Department.department')}
                        error={Boolean(errors.department?.message)}
                        helperText={errors.department?.message || ' '}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...register('name')}
                        disabled={isLoading || isSuccess}
                        label={t('Department.name')}
                        error={Boolean(errors.name?.message)}
                        helperText={errors.name?.message || ' '}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...register('image_url')}
                        disabled={isLoading || isSuccess}
                        label={t('Department.image')}
                        error={Boolean(errors.image_url?.message)}
                        helperText={errors.image_url?.message || ' '}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...register('email')}
                        disabled={isLoading || isSuccess}
                        label={t('Department.email')}
                        error={Boolean(errors.email?.message)}
                        helperText={errors.email?.message || ' '}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        {...register('title')}
                        disabled={isLoading || isSuccess}
                        label={t('Department.title')}
                        error={Boolean(errors.title?.message)}
                        helperText={errors.title?.message || ' '}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" marginY={2}>
                <Button
                    disabled={isLoading || isSuccess}
                    type="submit"
                    variant="contained"
                >
                    Save
                </Button>
            </Box>
        </form>
    );
}
