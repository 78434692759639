import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constantURL } from 'constants/urls';
import { DepartmentResponse } from 'types/department';

const token = localStorage.getItem('token');

export const departmentApi = createApi({
    reducerPath: 'departmentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL,
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*');
            if (token) headers.set('Authorization', `Bearer ${token}`);
            
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getDepartments: builder.mutation<DepartmentResponse[], string>({
            query: (companyId) => ({
                url: `${constantURL.OUT_GET_DEPARTMENTS}/${companyId}`,
            }),
        }),
    }),
});

export const { useGetDepartmentsMutation } = departmentApi;
