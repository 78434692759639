import React, { Dispatch, SetStateAction } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import 'localization/config';
import { termsConditions } from 'constants/terms';

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    scroll: DialogProps['scroll'];
};

export function TermsConditions({ open, setOpen, scroll }: Props): JSX.Element {
    const { t } = useTranslation();

    const handleClose = (): void => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{t('SignUp.termsConditions')}</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    {termsConditions.content.repeat(20)}
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
}

export default TermsConditions;