import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constantURL } from 'constants/urls';
import { CompanyResponse } from 'types/company';

const token = localStorage.getItem('token');

export const companyApi = createApi({
    reducerPath: 'companyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL,
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*');
            if (token) headers.set('Authorization', `Bearer ${token}`);
            
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCompany: builder.mutation<CompanyResponse, string>({
            query: (id) => ({ url: `${constantURL.OUT_GET_COMPANY}/${id}` }),
        }),
    }),
});

export const { useGetCompanyMutation } = companyApi;
