import React from 'react';
import { constantColor } from 'constants/colors';
import logo from 'assets/images/logoWhite.svg';
import background from 'assets/images/background.png';
import { useTranslation } from 'react-i18next';
import 'localization/config';
import styled from 'styled-components';
import { font } from 'constants/fonts';

const Wrapper = styled.div`
    position: relative;
    img {
        position: absolute;
        top: 200px;
        left: 86px;
    }
    background-color: ${constantColor.MAIN};
    background-image: url(${background});
    height: 100vh;
    padding: 0 150px 0 105px;
    .bigheading {
        color: ${constantColor.WHITE};
        font-weight: 500;
        font-size: 80px;
        line-height: 120px;
        margin: 265px 0 30px 0;
        &::before {
            content: '';
            display: block;
            height: 370px;
            position: absolute;
            opacity: 0.3;
            left: 120px;
            bottom: 311px;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
    .description {
        color: ${constantColor.WHITE};
        margin-left: 40px;
        max-width: 55%;
        font-size: ${font.SIZE_MEDIUM};
    }
`;

function WelcomeBack(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <img src={logo} alt="logo" />
            <h2 className="bigheading">{t('Welcome.back')}</h2>
            <p className="description">{t('Welcome.desc')}</p>
        </Wrapper>
    );
}

export default WelcomeBack;
