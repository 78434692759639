import decode from 'jwt-decode';
import { Token } from 'types/jwt-token';

export default function decodeJWT(): Token | undefined {
    const token = localStorage.getItem('token');

    if (token) return decode(token);

    return undefined;
}
