import * as Yup from 'yup';
import 'localization/config';
import { Ivalidation } from 'types/validations';
import { useTranslation } from 'react-i18next';

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

export const validateEmail = (email: string | undefined): boolean => {
    return Yup.string().email().isValidSync(email);
};

export const validatePhone = (phone: string | undefined): boolean => {
    return Yup.string().matches(phoneRegex).isValidSync(phone);
};

export const imageURLRegex =
    /()|(data:image\/[^;]+;base64[^"]+)|([(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*))/gi;

export const useValidation = (): Ivalidation => {
    const { t } = useTranslation();

    const signInSchema = Yup.object().shape({
        login: Yup.string()
            .required(t('Validations.loginReq'))
            .min(6, t('Validations.loginInv'))
            .test('email_or_phone', t('Validations.loginInv'), (value) => {
                return validateEmail(value) || validatePhone(value);
            }),
        password: Yup.string()
            .required(t('Validations.passEmty'))
            .min(6, t('Validations.passMin')),
    });

    const signupSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(t('Validations.firstReq'))
            .min(2, t('Validations.short'))
            .max(50, t('Validations.long')),
        last_name: Yup.string()
            .required(t('Validations.lastReq'))
            .min(2, t('Validations.short'))
            .max(50, t('Validations.long')),
        email: Yup.string()
            .email(t('Validations.emailInv'))
            .required(t('Validations.emailReq')),
        mobile_number: Yup.string()
            .required(t('Validations.phoneReq'))
            .matches(
                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                t('Validations.phoneInv')
            )
            .min(6, t('Validations.short'))
            .max(14, t('Validations.long')),
        password: Yup.string()
            .min(6, t('Validations.passMin'))
            .required(t('Validations.passEmty')),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('Validations.passMatch'))
            .required(t('Validations.confPassReq')),
    });

    const companySchema = Yup.object().shape({
        website_url: Yup.string()
            .matches(
                /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi,
                t('Validations.invalidUrl')
            )
            .required(t('Validations.urlReq'))
            .min(6, t('Validations.short'))
            .max(50, t('Validations.long')),
        name: Yup.string()
            .required(t('Validations.nameReq'))
            .min(2, t('Validations.short'))
            .max(25, t('Validations.long')),
        mobile_number: Yup.string()
            .required(t('Validations.phoneReq'))
            .matches(
                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                t('Validations.phoneInv')
            )
            .min(6, t('Validations.short'))
            .max(14, t('Validations.long')),
    });

    const departmentSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('Validations.nameReq'))
            .min(2, t('Validations.short'))
            .max(12, t('Validations.long')),
        department: Yup.string()
            .required(t('Validations.departmentReq'))
            .min(2, t('Validations.short'))
            .max(25, t('Validations.long')),
        phone_number: Yup.string()
            .required(t('Validations.phoneReq'))
            .matches(
                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                t('Validations.phoneInv')
            )
            .min(6, t('Validations.short'))
            .max(14, t('Validations.long')),

        image_url: Yup.string()
            .matches(
                /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi,
                t('Validations.invalidUrl')
            )
            .required(t('Validations.urlReq'))
            .min(6, t('Validations.short'))
            .max(50, t('Validations.long')),
    });

    const departmentSchemaSimple = Yup.object().shape({
        phone_number: Yup.string()
            .required(t('Validations.phoneReq'))
            .matches(
                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                t('Validations.phoneInv')
            )
            .min(6, t('Validations.short'))
            .max(24, t('Validations.long')),
        image_url: Yup.string().matches(
            imageURLRegex,
            'Invalid image URL! It needs to be a URL or base64 string'
        ),
        email: Yup.string().email('Invalid email!'),
    });

    const setNewPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, t('Validations.passMin'))
            .required(t('Validations.passEmty')),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('Validations.passMatch'))
            .required(t('Validations.confPassReq')),
    });

    return {
        signInSchema,
        signupSchema,
        companySchema,
        departmentSchema,
        departmentSchemaSimple,
        setNewPasswordSchema,
    };
};
