import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Department, Settings } from 'types/department';

const initialState: Settings = {
    departments: [],
};

export const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setDepartments: (state, action: PayloadAction<Department>) => {
            state.departments = [...state.departments, action.payload];
        },
        clearDepartments: (state) => {
            state.departments = [];
        },
    },
});

export const { setDepartments, clearDepartments } = settings.actions;

export default settings.reducer;
