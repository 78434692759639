import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Drawer,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { CustomLink } from 'pages/authentification/singIn';
import logo from 'assets/images/logo.svg';
import call from 'assets/images/icons/call-icon.svg';
import call2 from 'assets/images/icons/call-icon2.svg';
import palette from 'assets/images/palette.svg';
import callButton from 'assets/images/icons/call-button.svg';
import React, { SetStateAction } from 'react';
import { constantColor } from 'constants/colors';
import useBreakpoints from 'hooks/useBreakpoints';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { MuiChipsInputChip } from 'mui-chips-input';
import { useTranslation } from 'react-i18next';
import { useSendInfoMutation } from 'store/api/departmentsAPI';
import { useValidation } from 'constants/validationsHook';
import {HOME_PAGE, RETURN_BUTTON_TYPES} from "constants/home";
import decodeJWT from 'utils/decodeJWT';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Company, CompanyResponse } from 'types/company';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import { clearDepartments } from 'store/reducers/settings';
import { useDispatch } from 'react-redux';
import { Department } from 'types/department';
import { useSendCodeMutation } from 'store/api/codeSendingAPI';
import fallbackCopyTextToClipboard from 'utils/copyToClipboard';
import { ErrorAlert } from 'pages/forgotPassword';
import { APIError } from 'types/validations';
import { Alerts } from 'utils/alerts';
import DepartmentPage, { DepartmentProps } from './department';
import ColorDialog from './dialog';
import { CustomChipsInput } from './styles';

enum BUTTON_TYPES {
    CALL = 'call',
    TEXT = 'text',
}


enum BUTTON_COLORS {
    RED = 'red',
    ORANGE = 'orange',
}

enum BUTTON_EFFECTS {
    CLICK = 'click',
    HOVER = 'hover',
}

type SideNavbarProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
};

type NavLinkProps = {
    active?: boolean;
    onClick?: () => void;
    name: string;
    href: string;
};

function isLinkActive(link: string): boolean {
    return window.location.pathname.split('/')[1] === link;
}

function NavLink({
    active = false,
    name,
    href,
    onClick,
}: NavLinkProps): JSX.Element {
    return (
        <Box>
            <CustomLink
                onClick={() => {
                    if (onClick) onClick();
                }}
                style={{
                    fontWeight: active ? 600 : 300,
                }}
                fontStyle={{
                    color: active
                        ? constantColor.MAIN
                        : constantColor.GRAY_TEXT,
                }}
                underline="none"
                href={href}
            >
                {name}
            </CustomLink>
            {active && (
                <Box
                    sx={{
                        width: '100%',
                        border: `1px solid ${constantColor.MAIN}`,
                    }}
                />
            )}
        </Box>
    );
}

function Navbar(): JSX.Element {
    return (
        <Stack direction="row" spacing={5}>
            <NavLink active={isLinkActive('home')} name={HOME_PAGE.HOME} href="/" />
            <NavLink
                active={isLinkActive('call-data')}
                name={HOME_PAGE.CALL_DATA}
                href="/"
            />
            <NavLink active={isLinkActive('company')} name={HOME_PAGE.COMPANY} href="/" />
            <NavLink active={isLinkActive('edit')} name={HOME_PAGE.EDIT_INFO} href="/" />
            <NavLink
                onClick={() => {
                    localStorage.clear();
                }}
                name={HOME_PAGE.LOGOUT}
                href="*"
            />
        </Stack>
    );
}

function SideNavbar({ open, setOpen }: SideNavbarProps): JSX.Element {
    return (
        <Drawer anchor="left" open={open}>
            <Box>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        sx={{
                            paddingX: 3,
                            paddingTop: 3,
                        }}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <List>
                    <ListItem>
                        <ListItemButton>
                            <CustomLink href="/">Home</CustomLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <CustomLink href="/">Call Data</CustomLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <CustomLink href="/">Company</CustomLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <CustomLink href="/">Edit profile</CustomLink>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <CustomLink
                                onClick={() => {
                                    localStorage.clear();
                                }}
                                href="*"
                            >
                                Logout
                            </CustomLink>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
}

export default function Home(): JSX.Element {
    const { t } = useTranslation();
    const { sm } = useBreakpoints();
    const { companySchema } = useValidation();
    const user = decodeJWT();
    const dispatch = useDispatch();

    const [drawer, setDrawer] = React.useState(false);
    const [keywords, setKeyWords] = React.useState<MuiChipsInputChip[]>([]);
    const [helper, setHelper] = React.useState('');
    const [loadingCompany, setLoadingCompany] = React.useState(true);
    const [company, setCompany] = React.useState<CompanyResponse>();
    const [code, setCode] = React.useState('');
    const [colors, setColors] = React.useState(HOME_PAGE.DEFAULT_COLOR);
    const [openModal, setOpenModal] = React.useState(false);
    const [buttonText, setButtonText] = React.useState<string>(
        t(HOME_PAGE.BUTTON_TEXT)
    );
    const [email, setEmail] = React.useState('');
    const [buttonType, setButtonType] =
        React.useState<`${BUTTON_TYPES}`>('call');
    const [buttonColor, setButtonColor] =
        React.useState<`${BUTTON_COLORS}`>('red');
    const [buttonEffect, setButtonEffect] =
        React.useState<`${BUTTON_EFFECTS}`>('click');
    const [moreThanOneDepartment, setMoreThanOneDepartment] =
        React.useState(false);
    const [departmentComponents, setDepartmentComponents] = React.useState<
        JSX.Element[]
    >([]);
    const [departmentData, setDepartmentData] = React.useState<Department[]>(
        []
    );

    const [
        send,
        {
            isSuccess: isSuccessCompany,
            isLoading: isLoadingCompany,
            data: companyData,
        },
    ] = useSendInfoMutation();
    const [
        sendCode,
        {
            isLoading: isLoadingEmail,
            isSuccess: isSuccessEmail,
            isError: isErrorEmail,
            error: errorEmail,
        },
    ] = useSendCodeMutation();

    const {
        register: registerCompany,
        handleSubmit: handleSubmitCompany,
        formState: { errors: companyErrors },
    } = useForm<Company>({
        resolver: yupResolver<Yup.AnyObjectSchema>(companySchema),
        defaultValues: {
            website_url: '',
            mobile_number: '',
            name: '',
            keywords: [],
        },
    });

    const companyUrl = `${window.location.origin}/company/${companyData?.url_id}`;

    const getEmbedIconCode = (url: string): string => {
        return `
            <a target="_blank" href="${url}">
                <img src="http://soundglide-common-files.s3-website.us-east-2.amazonaws.com/logo.svg" alt="soundglide-logo"/>
            </a>
        `;
    };

    function createDepartment(): JSX.Element | undefined {
        if (company?.id) {
            const title = (): string => {
                if (!departmentComponents.length) return '1st line';
                if (departmentComponents.length === 1) return '2nd line';
                if (departmentComponents.length === 2) return '3rd line';

                return `${departmentComponents.length + 1}th line`;
            };

            const NewComponent: JSX.Element =
                React.cloneElement<DepartmentProps>(<DepartmentPage />, {
                    title: title(),
                    companyId: company?.id,
                    index:
                        departmentData.length - 1 > 0
                            ? departmentData.length - 1
                            : 0,
                    setDepartmentData,
                });

            return NewComponent;
        }

        return undefined;
    }

    const ButtonType = (): typeof RETURN_BUTTON_TYPES.SIMPLE | typeof RETURN_BUTTON_TYPES.SINGLE | typeof RETURN_BUTTON_TYPES.ARRAY  => {
        if (buttonType === BUTTON_TYPES.CALL) return RETURN_BUTTON_TYPES.SIMPLE;
        if (departmentData.length > 1) return RETURN_BUTTON_TYPES.ARRAY;
        if (buttonType === BUTTON_TYPES.TEXT) return RETURN_BUTTON_TYPES.SINGLE;

        return RETURN_BUTTON_TYPES.SIMPLE;
    };

    function generateCode(): void {

        const codeGenerationArray: string[] = ['<script>\n'];
        const templateStrings = (key: string, value: string | undefined | boolean, end?: boolean): string=> {

            return `\t${key}:"${value}",`
        }
        const windowType = `window.type = "${ButtonType()}";`;
        const windowPlace = `window.place = "right";`;
        const windowColors = colors;

        const windowSimple =
            ButtonType() === 'simple' &&
            `window.simple = {
                ${templateStrings('phone_number',company?.mobile_number)}
                ${templateStrings('is_red',buttonColor === 'red')}
            };`;

        const windowButtonText = !windowSimple && `window.buttonText = "${buttonText}";`;

        const windowEffect = !windowSimple && `window.effect = "${buttonEffect}";`;

        const windowSingleDispatcher =
            ButtonType() === 'single' &&
            `window.singleDispatcher = {
                ${templateStrings('phone_number',company?.mobile_number)}
                ${templateStrings('name',company?.name)} ${
                departmentData.length ?templateStrings('\n\t\tphoto',departmentData[0]?.image_url): ''}
            };`
        const windowArray =
            moreThanOneDepartment &&
            departmentData.length > 1 &&
            `window.array = [${departmentData.map(
                (el) =>
                    `{
                ${templateStrings('phone_number',el?.phone_number)}
                ${templateStrings('name',company?.name)}
                ${templateStrings('position',el.department)}
                ${templateStrings('name',company?.name)}
                ${templateStrings('photo',el.image_url)}
            }`
            )}];`;

        if (windowType) codeGenerationArray.push(windowType);
        if (windowPlace) codeGenerationArray.push(windowPlace);
        if (windowEffect) codeGenerationArray.push(windowEffect);
        if (windowButtonText) codeGenerationArray.push(windowButtonText);
        if (windowColors && !windowSimple){
            codeGenerationArray.push(windowColors);
        }
        if (windowSimple) codeGenerationArray.push(windowSimple)
        if (windowSingleDispatcher)
            codeGenerationArray.push(windowSingleDispatcher);
        if (windowArray) codeGenerationArray.push(windowArray);

        codeGenerationArray.push(
            `
            </script>
            `.replace(/ {4}|[\t]/gm, ''),
            `<script defer="defer" src="https://sound-glide-front.s3.us-east-2.amazonaws.com/not_static/js/main.js"></script>
            <link href="https://sound-glide-front.s3.us-east-2.amazonaws.com/not_static/css/main.css" rel="stylesheet">

            <div id="sound-glide-button"></div>`.replace(/ {4}|[\t]/gm, '')
        );

        setCode(codeGenerationArray.join('\n'));
    }

    const onSubmit: SubmitHandler<Company> = async (values) => {
        if (user) {
            setLoadingCompany(true);
            send({ ...values, keywords, userId: user.id })
                .unwrap()
                .then((res: CompanyResponse) => {
                    setCompany(res);
                    setLoadingCompany(!isLoadingCompany && isSuccessCompany);

                    return res;
                });
        }
    };

    React.useEffect(() => {
        setDepartmentComponents([]);
        dispatch(clearDepartments());
        setDepartmentData([]);
        setCode('');
        setMoreThanOneDepartment(false);
    }, [buttonType, company]);

    React.useEffect(() => {
        if (moreThanOneDepartment) {
            const newDp = createDepartment();

            if (newDp) setDepartmentComponents([newDp]);
        } else {
            setDepartmentComponents([]);
            setDepartmentData([]);
        }
    }, [moreThanOneDepartment]);

    React.useEffect(() => {
        if (isErrorEmail) {
            const typedError = errorEmail as APIError;

            ErrorAlert(typedError.data.message as string);
        }
    }, [isErrorEmail]);

    React.useEffect(() => {
        if (isSuccessEmail) {
            Alerts.fire({
                title: t(HOME_PAGE.EMAIL_SENT),
                showConfirmButton: true,
                icon: 'success',
                confirmButtonColor: constantColor.MAIN,
            });
        }
    }, [isSuccessEmail]);

    return (
        <Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent={sm ? 'center' : 'flex-start'}
                marginBottom={5}
                boxShadow={`0px 5px 20px -1px ${constantColor.GRAY_LIGHT}`}
            >
                {sm && (
                    <Box position="absolute" left={10} top={40}>
                        <IconButton
                            onClick={() => {
                                setDrawer(!drawer);
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <SideNavbar open={drawer} setOpen={setDrawer} />
                    </Box>
                )}
                <Box
                    display="flex"
                    justifyContent={sm ? 'center' : 'flex-start'}
                    paddingX={!sm ? 4 : 0}
                    paddingY={2}
                >
                    <CustomLink href="/">
                        <img src={logo} alt="logo" />
                    </CustomLink>
                    <Typography
                        fontFamily="Quattrocento Sans"
                        fontWeight="bold"
                        fontSize={20}
                        color={constantColor.MAIN}
                        alignSelf="center"
                        marginLeft={1}
                    >
                        soundglide.com
                    </Typography>
                </Box>
                {!sm && (
                    <Container maxWidth="lg">
                        <Box display="flex" justifyContent="center">
                            <Navbar />
                        </Box>
                    </Container>
                )}
            </Box>
            <Container maxWidth="xl">
                <Typography
                    marginBottom={5}
                    fontSize={20}
                    textAlign="center"
                    fontFamily="Poppins"
                >
                    {t('Home.input')}
                </Typography>
                {/* Company Info */}
                <Container maxWidth="lg">
                    <Grid
                        component="form"
                        onSubmit={handleSubmitCompany(onSubmit)}
                        container
                        spacing={2}
                        rowSpacing={3}
                        marginBottom={10}
                    >
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...registerCompany('website_url')}
                                error={Boolean(
                                    companyErrors.website_url?.message
                                )}
                                helperText={companyErrors.website_url?.message}
                                fullWidth
                                label={t('Home.url')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...registerCompany('mobile_number')}
                                error={Boolean(
                                    companyErrors.mobile_number?.message
                                )}
                                helperText={
                                    companyErrors.mobile_number?.message
                                }
                                fullWidth
                                label={t('Home.phone')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...registerCompany('name')}
                                error={Boolean(companyErrors.name?.message)}
                                helperText={companyErrors.name?.message || ' '}
                                fullWidth
                                label={t('Home.name')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} marginBottom={sm ? 5 : 0}>
                            <CustomChipsInput
                                autoComplete="none"
                                placeholder=""
                                fullWidth
                                label={t('Home.keywords')}
                                onFocus={() => setHelper(t('Home.wordsHelper'))}
                                onBlur={() => setHelper('')}
                                helperText={helper}
                                FormHelperTextProps={{
                                    disabled: true,
                                }}
                                variant="outlined"
                                value={keywords}
                                onChange={(newWord) => {
                                    setKeyWords(newWord);
                                }}
                                renderChip={(Component, props) => (
                                    <Component
                                        {...props}
                                        deleteIcon={<CloseIcon />}
                                    />
                                )}
                            />
                        </Grid>
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            <Button
                                type="submit"
                                disabled={isSuccessCompany}
                                sx={{
                                    paddingX: 3,
                                    paddingY: 1,
                                    fontFamily: 'Poppins',
                                    textTransform: 'none',
                                    fontWeight: 400,
                                    fontSize: 16,
                                }}
                                variant="contained"
                            >
                                {t('Department.save')}
                            </Button>
                        </Box>
                    </Grid>
                </Container>
                {/* Button Info */}
                {loadingCompany && !companyData ? undefined : (
                    <Container>
                        <Container maxWidth="lg">
                            <Stack
                                display="flex"
                                justifyContent="center"
                                direction="row"
                                spacing={4}
                                flexWrap="wrap"
                            >
                                <Stack direction="row">
                                    <FormControlLabel
                                        label={
                                            <Typography
                                                whiteSpace="nowrap"
                                                textOverflow="ellipsis"
                                                fontFamily="Poppins"
                                            >
                                                {t('Department.wantIcon')}
                                            </Typography>
                                        }
                                        control={
                                            <Radio
                                                value={BUTTON_TYPES.CALL}
                                                onChange={() => {
                                                    setButtonType(
                                                        BUTTON_TYPES.CALL
                                                    );
                                                }}
                                                name="button-type"
                                                checked={
                                                    buttonType ===
                                                    BUTTON_TYPES.CALL
                                                }
                                            />
                                        }
                                    />
                                    <img src={call} alt="call" />
                                </Stack>
                                <Stack direction="row">
                                    <FormControlLabel
                                        label={
                                            <Typography
                                                whiteSpace="nowrap"
                                                fontFamily="Poppins"
                                                textOverflow="ellipsis"
                                            >
                                                {t('Department.wantButton')}
                                            </Typography>
                                        }
                                        control={
                                            <Radio
                                                value={BUTTON_TYPES.TEXT}
                                                onChange={() => {
                                                    setButtonType(
                                                        BUTTON_TYPES.TEXT
                                                    );
                                                }}
                                                name="button-type"
                                                checked={
                                                    buttonType ===
                                                    BUTTON_TYPES.TEXT
                                                }
                                            />
                                        }
                                    />
                                    <img src={callButton} alt="call" />
                                </Stack>
                            </Stack>
                            <Divider
                                sx={{
                                    marginY: 3,
                                }}
                            />
                            {buttonType === BUTTON_TYPES.CALL && (
                                <Box>
                                    <Stack
                                        display="flex"
                                        justifyContent="center"
                                        direction="row"
                                        spacing={4}
                                        flexWrap="wrap"
                                        marginBottom={3}
                                    >
                                        <Stack direction="row">
                                            <FormControlLabel
                                                label={
                                                    <Typography fontFamily="Poppins">
                                                        {t(
                                                            'Department.redButton'
                                                        )}
                                                    </Typography>
                                                }
                                                control={
                                                    <Radio
                                                        value={
                                                            BUTTON_COLORS.RED
                                                        }
                                                        onChange={() => {
                                                            setButtonColor(
                                                                BUTTON_COLORS.RED
                                                            );
                                                        }}
                                                        checked={
                                                            buttonColor ===
                                                            BUTTON_COLORS.RED
                                                        }
                                                        name="button-color"
                                                    />
                                                }
                                            />
                                            <img
                                                height="25"
                                                width="25"
                                                src={call}
                                                alt="call-red"
                                            />
                                        </Stack>
                                        <Stack direction="row">
                                            <FormControlLabel
                                                label={
                                                    <Typography fontFamily="Poppins">
                                                        {t(
                                                            'Department.orangeButton'
                                                        )}
                                                    </Typography>
                                                }
                                                control={
                                                    <Radio
                                                        value={
                                                            BUTTON_COLORS.ORANGE
                                                        }
                                                        onChange={() => {
                                                            setButtonColor(
                                                                BUTTON_COLORS.ORANGE
                                                            );
                                                        }}
                                                        checked={
                                                            buttonColor ===
                                                            BUTTON_COLORS.ORANGE
                                                        }
                                                        name="button-color"
                                                    />
                                                }
                                            />
                                            <img
                                                height="25"
                                                width="25"
                                                src={call2}
                                                alt="call-orange"
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                            {buttonType === BUTTON_TYPES.TEXT && (
                                <Stack
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Stack marginBottom={3}>
                                        <Typography
                                            textAlign="center"
                                            fontSize={16}
                                            marginBottom={1}
                                            fontFamily="Poppins"
                                        >
                                            {t('Department.textButton')}
                                        </Typography>
                                        <Stack direction="row" spacing={2}>
                                            <TextField
                                                onChange={({
                                                    target: { value: text },
                                                }) => {
                                                    setButtonText(text);
                                                }}
                                                placeholder={t(
                                                    'Department.buttonText'
                                                )}
                                            />
                                            <Button
                                                onClick={() => {
                                                    setOpenModal(true);
                                                }}
                                                color="secondary"
                                            >
                                                <img
                                                    src={palette}
                                                    alt="palette"
                                                />
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <Typography
                                        textAlign="center"
                                        fontFamily="Poppins"
                                        fontSize={16}
                                    >
                                        {t('Department.effects')}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        marginBottom={2}
                                    >
                                        <RadioGroup row name="button-effects">
                                            <FormControlLabel
                                                label={
                                                    <Typography fontFamily="Poppins">
                                                        {t('Department.click')}
                                                    </Typography>
                                                }
                                                control={
                                                    <Radio
                                                        checked={
                                                            buttonEffect ===
                                                            BUTTON_EFFECTS.CLICK
                                                        }
                                                        value={
                                                            BUTTON_EFFECTS.CLICK
                                                        }
                                                        onChange={() => {
                                                            setButtonEffect(
                                                                BUTTON_EFFECTS.CLICK
                                                            );
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={
                                                    <Typography fontFamily="Poppins">
                                                        {t('Department.hover')}
                                                    </Typography>
                                                }
                                                control={
                                                    <Radio
                                                        checked={
                                                            buttonEffect ===
                                                            BUTTON_EFFECTS.HOVER
                                                        }
                                                        value={
                                                            BUTTON_EFFECTS.HOVER
                                                        }
                                                        onChange={() => {
                                                            setButtonEffect(
                                                                BUTTON_EFFECTS.HOVER
                                                            );
                                                        }}
                                                    />
                                                }
                                            />
                                        </RadioGroup>
                                    </Stack>
                                    <Stack
                                        marginBottom={
                                            moreThanOneDepartment ? 2 : 0
                                        }
                                    >
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    fontSize={16}
                                                    fontFamily="Poppins"
                                                >
                                                    {t(
                                                        'Department.moreThanOne'
                                                    )}
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    checked={
                                                        moreThanOneDepartment
                                                    }
                                                    onChange={() => {
                                                        setMoreThanOneDepartment(
                                                            !moreThanOneDepartment
                                                        );
                                                    }}
                                                />
                                            }
                                        />
                                    </Stack>
                                    {moreThanOneDepartment && (
                                        <>
                                            {departmentComponents}
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                            >
                                                <Button
                                                    sx={{
                                                        marginTop: 1,
                                                    }}
                                                    onClick={() => {
                                                        const newDp =
                                                            createDepartment();

                                                        if (newDp)
                                                            setDepartmentComponents(
                                                                (prev) => [
                                                                    ...prev,
                                                                    newDp,
                                                                ]
                                                            );
                                                    }}
                                                >
                                                    {t('Department.moreLines')}
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    <ColorDialog
                                        setColors={setColors}
                                        open={openModal}
                                        handleClose={() => {
                                            setOpenModal(false);
                                        }}
                                    />
                                </Stack>
                            )}
                        </Container>
                        <Container maxWidth="lg">
                            <Grid paddingTop={8} container>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Button
                                        onClick={() => {
                                            generateCode();
                                        }}
                                        fullWidth
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                        variant="contained"
                                    >
                                        {t('Department.generate')}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <CopyBlock
                                        language="javascript"
                                        showLineNumbers
                                        codeBlock
                                        theme={atomOneLight}
                                        text={code}
                                    />
                                </Grid>
                            </Grid>
                            <Box paddingY={2}>
                                <Typography
                                    fontFamily="Poppins"
                                    textAlign="center"
                                >
                                    {t('Home.pageLink')}&nbsp;
                                    <Link
                                        target="_blank"
                                        href={`${window.location.origin}/company/${companyData?.url_id}`}
                                    >
                                        {`${window.location.origin}/company/${companyData?.url_id}`}
                                    </Link>
                                </Typography>
                            </Box>
                            <Typography fontFamily="Poppins" textAlign="center">
                                {t('Home.icon')}
                            </Typography>
                            <Box
                                paddingY={2}
                                display="flex"
                                justifyContent="center"
                                onClick={async () => {
                                    if ('clipboard' in navigator) {
                                        await navigator.clipboard.writeText(
                                            getEmbedIconCode(companyUrl)
                                        );
                                    } else {
                                        fallbackCopyTextToClipboard(code);
                                    }
                                }}
                            >
                                <img
                                    title="Click to copy"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    src={logo}
                                    alt="logo"
                                />
                            </Box>
                            <Stack
                                maxWidth={500}
                                spacing={2}
                                paddingY={6}
                                margin="auto"
                            >
                                <TextField
                                    onChange={({ target: { value } }) => {
                                        setEmail(value);
                                    }}
                                    label={t('Department.email')}
                                />
                                <Button
                                    disabled={isLoadingEmail || !code || !email}
                                    sx={{
                                        paddingX: 3,
                                        paddingY: 1,
                                        fontFamily: 'Poppins',
                                        textTransform: 'none',
                                        fontWeight: 400,
                                        fontSize: 16,
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        sendCode({
                                            code,
                                            email,
                                        });
                                    }}
                                >
                                    {t('Department.sendCode')}
                                </Button>
                            </Stack>
                        </Container>
                    </Container>
                )}
            </Container>
        </Box>
    );
}
