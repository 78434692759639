import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constantURL } from 'constants/urls';
import { Company, CompanyResponse } from 'types/company';
import { Department } from 'types/department';

export const addCompanyInfo = createApi({
    reducerPath: 'addCompanyInfo',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL,
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*');
            
            return headers;
        },
    }),

    endpoints: (build) => ({
        sendInfo: build.mutation<CompanyResponse, unknown>({
            query: (body: Company) => {
                return {
                    url: constantURL.OUT_SEND_COMPANY,
                    method: 'post',
                    body,
                };
            },
        }),
        addDepartment: build.mutation({
            query: (body: Department) => {
                return {
                    url: constantURL.OUT_SEND_DEPARTMENTS,
                    method: 'post',
                    body,
                };
            },
        }),
    }),
});

export const { useSendInfoMutation, useAddDepartmentMutation } = addCompanyInfo;
