import React, { Dispatch, SetStateAction, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import 'localization/config';
import { CustomButton } from 'components/Button';
import { RgbaColorPicker } from 'react-colorful';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { ButtonContainer, Palette, PaletteHeading } from './styles';

export default function ColorDialog({
    open,
    handleClose,
    setColors,
}: {
    open: boolean;
    handleClose: () => void;
    setColors: Dispatch<SetStateAction<string>>;
}): JSX.Element {
    const [popUpColor, setpopUpColor] = useState({
        r: 255,
        g: 255,
        b: 255,
        a: 1,
    });
    const [itemColor, setitemColor] = useState({
        r: 219,
        g: 244,
        b: 222,
        a: 1,
    });
    const [buttonColor, setbuttonColor] = useState({
        r: 33,
        g: 69,
        b: 37,
        a: 1,
    });
    const [textColor, settextColor] = useState({
        r: 255,
        g: 255,
        b: 255,
        a: 1,
    });
    const { t } = useTranslation();

    function savingColor(): void {
        setColors(`
        window.colors = {
            popupBackground: "rgba(${popUpColor.r}, ${popUpColor.g}, ${popUpColor.b}, ${popUpColor.a})",
            itemBackground: "rgba(${itemColor.r}, ${itemColor.g}, ${itemColor.b}, ${itemColor.a})",
            button: "rgba(${buttonColor.r}, ${buttonColor.g}, ${buttonColor.b}, ${buttonColor.a})",
            text: "rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, ${textColor.a})",
            buttonText: 'white',
            initialButtonText: 'white',
            initialButtonBackground: '#214525',
        };
        `);
        handleClose();
    }

    return (
        <Dialog
            PaperProps={{
                style: { borderRadius: 40 },
            }}
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle
                style={{ fontSize: '30px', paddingBottom: '20px' }}
                textAlign="center"
            >
                {t('Colors.settings')}
            </DialogTitle>
            <DialogContent
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '25px',
                }}
            >
                <div>
                    <PaletteHeading>{t('Colors.popUp')}</PaletteHeading>
                    <Palette>
                        <RgbaColorPicker
                            color={popUpColor}
                            onChange={setpopUpColor}
                        />
                    </Palette>
                </div>
                <div>
                    <PaletteHeading>{t('Colors.button')}</PaletteHeading>
                    <Palette>
                        <RgbaColorPicker
                            color={buttonColor}
                            onChange={setbuttonColor}
                        />
                    </Palette>
                </div>
                <div>
                    <PaletteHeading>{t('Colors.text')}</PaletteHeading>
                    <Palette>
                        <RgbaColorPicker
                            color={textColor}
                            onChange={settextColor}
                        />
                    </Palette>
                </div>
                <div>
                    <PaletteHeading>{t('Colors.item')}</PaletteHeading>
                    <Palette>
                        <RgbaColorPicker
                            color={itemColor}
                            onChange={setitemColor}
                        />
                    </Palette>
                </div>
            </DialogContent>
            <DialogActions>
                <ButtonContainer>
                    <CustomButton
                        className="save"
                        type="button"
                        onClick={() => savingColor()}
                    >
                        {t('Colors.save')}
                    </CustomButton>
                </ButtonContainer>
            </DialogActions>
        </Dialog>
    );
}
