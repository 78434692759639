import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constantURL } from 'constants/urls';

const token = localStorage.getItem('token');

export const codeSending = createApi({
    reducerPath: 'codeSending',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_URL,
        prepareHeaders: (headers) => {
            headers.set('Access-Control-Allow-Origin', '*');
            if (token) headers.set('Authorization', `Bearer ${token}`);
            
            return headers;
        },
    }),

    endpoints: (build) => ({
        sendCode: build.mutation({
            query: (body: { email: string; code: string }) => {
                return {
                    url: constantURL.OUT_SEND_CODE,
                    method: 'post',
                    body: {
                        subject: 'Soundglide Embed Code',
                        body: body.code,
                        email: body.email,
                    },
                };
            },
        }),
    }),
});

export const { useSendCodeMutation } = codeSending;
