import { constantURL } from 'constants/urls';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'store';

type Props = {
    component: React.ReactElement;
};

export default function SecuredRoute({
    component,
    ...props
}: Props): JSX.Element {
    const token = useAppSelector((state) => state.login.token);

    return token ? (
        React.cloneElement(component, props)
    ) : (
        <Navigate to={constantURL.LOGIN} />
    );
}
